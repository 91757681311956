import { graphql, Link } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../components/content_wrapper/content_wrapper';
import { SEO } from '../components/seo';
import { GatsbyPageProps } from '../gatsby-types';
import Layout from '../layouts/layout';
import { filterTagsByPrefix, PrismicTagPrefixes } from '../lib/prismic_tags';
import { RoutePaths } from '../lib/routes';
import { Style } from '../models/style';
import * as styles from './techniques.module.less';

export const query = graphql`
  query {
    referenceData {
      styles {
        display_name
        id
        name
        url_path
      }
    }
    allPrismicArticle {
      nodes {
        tags
      }
    }
  }
`;

const TechniquesListPage = (props: GatsbyPageProps) => {
  const techniques = props.data.referenceData.styles as Style[];
  const styleNamesWithCounts = {} as Record<string, number>;
  props.data.allPrismicArticle.nodes.forEach((node: { tags: string[] }) => {
    filterTagsByPrefix(node.tags, PrismicTagPrefixes.STYLE).forEach((styleName) => {
      styleNamesWithCounts[styleName] = styleNamesWithCounts[styleName] || 0;
      styleNamesWithCounts[styleName] += 1;
    });
  });

  return (
    <Layout>
      <SEO title="Techniques" />
      <ContentWrapper>
        <article className={styles.page}>
          <h1>Techniques</h1>
          <ul className={styles.techniquesList}>
            {techniques.map((technique) => {
              const tagCount = styleNamesWithCounts[technique.name] || 0;
              return (
                <li key={technique.url_path} className={styles.techniques}>
                  <Link
                    to={`${RoutePaths.TECHNIQUES}${technique.url_path}`}
                    className={styles.techniquesLink}
                  >
                    <span className={styles.techniquesName}>
                      {technique.display_name} [{tagCount}]
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </article>
      </ContentWrapper>
    </Layout>
  );
};

export default TechniquesListPage;
